import { type SearchResponse } from '@algolia/client-search'
import type { LinkInternal } from '@backmarket/http-api'
import { mapSingleAlgoliaHitToProduct } from '@backmarket/nuxt-layer-cms/products/algolia/algoliaAdapter'
import type { AlgoliaProductHit } from '@backmarket/nuxt-layer-cms/products/algolia/types'
import type { Product } from '@backmarket/nuxt-layer-recommendation/models/product'
import type { I18nDefinition } from '@backmarket/nuxt-module-i18n/types'

import { isNotNullNorUndefined } from '~/utils/object/isNotNullNorUndefined'
import { formatBytes } from '~/utils/string/formatBytes'

import type { ModelCharacteristics } from '../../models/model-characteristics'
import type { I18nFn } from '../i18n.types'

import type {
  Attribute as AlgoliaComparableModelsAttribute,
  AlgoliaComparableModelsIndexHit,
  ModelsComparisonAlgoliaResponse,
} from './comparable-model-algolia-hit'
import {
  EmptyAlgoliaResponseHits,
  MissingBackboxIndexAlgoliaResponse,
  MissingModelsComparisonIndexAlgoliaResponse,
} from './errors'

const formatBooleanValue = ({
  value,
  i18n,
  translations,
}: {
  value: boolean
  i18n: I18nFn
  translations: ModelsComparisonTranslations
}) => (value ? i18n(translations.yes) : i18n(translations.no))

const formatCharacteristicValue = ({
  value,
  unit,
  i18n,
  translations,
}: {
  value: string | number | boolean | null
  unit?: string
  i18n: I18nFn
  translations: ModelsComparisonTranslations
}) => {
  if (unit === 'bytes') return formatBytes(value as number)

  if (typeof value === 'boolean')
    return formatBooleanValue({ value, i18n, translations })

  if (unit) return `${value} ${unit}`

  return `${value}`
}

function getTranslationFromId({
  translationId,
  translations,
  i18n,
}: {
  translationId: string
  i18n: I18nFn
  translations: ModelsComparisonTranslations
}) {
  const translation = Object.values(translations).find(
    (tr) => tr.id === translationId,
  )

  return translation ? i18n(translation) : ''
}

function mapSingleAttribute({
  attribute,
  i18n,
  translations,
}: {
  attribute: AlgoliaComparableModelsAttribute
  i18n: I18nFn
  translations: ModelsComparisonTranslations
}) {
  const { label: translationId, value, unit } = attribute
  const formattedValue = formatCharacteristicValue({
    value,
    unit,
    i18n,
    translations,
  })

  const attributeLabel = getTranslationFromId({
    i18n,
    translationId,
    translations,
  })

  return { label: attributeLabel, value: formattedValue }
}

function mapGroupedCharacteristics({
  specifications,
  i18n,
  translations,
}: {
  specifications: AlgoliaComparableModelsIndexHit['specifications']
  i18n: I18nFn
  translations: ModelsComparisonTranslations
}) {
  return specifications.groups.map((group) => {
    const { attributes, label: categoryTranslationId, value } = group

    const categoryLabel = getTranslationFromId({
      i18n,
      translations,
      translationId: categoryTranslationId,
    })

    const characteristics = attributes
      .filter((attr) => isNotNullNorUndefined(attr?.value))
      .map((attribute) => mapSingleAttribute({ attribute, i18n, translations }))

    return { category: categoryLabel, characteristics, rating: value?.internal }
  })
}

type ModelsComparisonTranslations = Record<string, I18nDefinition> &
  Record<'yes' | 'no', I18nDefinition>

export function mapAlgoliaResponsesToModelCharacteristics(
  algoliaResponses: ModelsComparisonAlgoliaResponse[],
  {
    locale = 'en-us',
    i18n,
    translations,
  }: {
    locale?: string
    i18n: I18nFn
    translations: ModelsComparisonTranslations
  },
): ModelCharacteristics {
  const listOfResults = algoliaResponses.map((res) => res.results[0])
  const backboxResults = listOfResults.find((r) =>
    r.index?.includes('backbox'),
  ) as SearchResponse<AlgoliaProductHit> | undefined

  if (!backboxResults) throw new MissingBackboxIndexAlgoliaResponse()

  const comparableModelsIndexResults = listOfResults.find((r) =>
    r.index?.includes('model'),
  ) as SearchResponse<AlgoliaComparableModelsIndexHit> | undefined

  if (!comparableModelsIndexResults)
    throw new MissingModelsComparisonIndexAlgoliaResponse()

  const [comparableModelsIndexHit, backboxIndexHit] = [
    comparableModelsIndexResults,
    backboxResults,
  ].map((r) => r?.hits?.[0]) as [
    AlgoliaComparableModelsIndexHit | undefined,
    AlgoliaProductHit | undefined,
  ]

  if (!comparableModelsIndexHit) throw new EmptyAlgoliaResponseHits()

  const product: Partial<Product> = backboxIndexHit
    ? mapSingleAlgoliaHitToProduct(backboxIndexHit)
    : {}

  const image = product.image || comparableModelsIndexHit.model.image

  const link =
    product.productPageLink ||
    (comparableModelsIndexHit.model.links[locale] as LinkInternal) ||
    null

  const groupedCharacteristics = mapGroupedCharacteristics({
    specifications: comparableModelsIndexHit.specifications,
    i18n,
    translations,
  })

  return {
    ...product,
    image,
    productPageLink: link || undefined,
    groupedCharacteristics,
  }
}
