import { navigateTo, useAsyncData, useNuxtApp, useRoute } from '#imports'

import {
  type ModelComparisonCompareModels,
  type ModelComparisonModel,
  getModelsComparisonResults,
} from '@backmarket/http-api/src/api-specs-model-comparison'
import type { InitializableAlgoliaClient } from '@backmarket/nuxt-layer-cms/products/algolia/useAlgolia'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useI18nLocale } from '@backmarket/nuxt-module-i18n/useI18nLocale'
import { isBrowser } from '@backmarket/utils/env/isBrowser'
import { isEqual } from '@backmarket/utils/object/isEqual'
import algoliasearch from 'algoliasearch'

import { isNotNullNorUndefined } from '~/utils/object/isNotNullNorUndefined'

import { type SelectedModels } from '../models/selected-models'
import { mapAlgoliaResponsesToModelCharacteristics } from '../utils/algolia/algoliaAdapter'
import type {
  ModelsComparisonAlgoliaHit,
  ModelsComparisonAlgoliaResponse,
} from '../utils/algolia/comparable-model-algolia-hit'
import translations from '../utils/characteristics.translations'

import { useModelsComparisonAlgoliaSearch } from './useAlgoliaSearch'

function useGetModelsSlugsFromRoute() {
  const route = useRoute()
  const { productA, productB } = route.params as {
    productA: string
    productB: string
  }
  const modelsSlugs = [productA, productB]

  return { modelsSlugs }
}

function useBuildModelsComparisonData() {
  const i18n = useI18n()
  const locale = useI18nLocale()

  function buildModelsComparisonData({
    comparisonResults,
    modelsData,
  }: {
    comparisonResults: ModelComparisonCompareModels
    modelsData: {
      algoliaData: ModelsComparisonAlgoliaResponse[]
      model: ModelComparisonModel
    }[]
  }) {
    const modelsNames = comparisonResults.models.map(
      (model) => model.compareName,
    ) as SelectedModels

    const characteristicsOfEachModel = modelsData
      ? modelsData.filter(isNotNullNorUndefined).map(({ algoliaData }) => {
          return mapAlgoliaResponsesToModelCharacteristics(algoliaData, {
            i18n,
            translations,
            locale,
          })
        })
      : []

    return {
      category: comparisonResults.category,
      content: comparisonResults.content,
      algoliaConfig: comparisonResults.algoliaConfig,
      link: comparisonResults.link,
      comparison: {
        modelsNames,
        characteristics: characteristicsOfEachModel,
      },
    }
  }

  return { buildModelsComparisonData }
}

function shouldRedirect(
  link: ModelComparisonCompareModels['link'],
  currentRoute: ReturnType<typeof useRoute>,
) {
  // no client-side redirection to avoid duplicate requests and page_viewed event
  // a client-side redirection triggers a second Vue Router afterEach hook, causing the duplicate event
  // see issue reported here https://github.com/nuxt/nuxt/issues/14287
  if (isBrowser()) return false

  const isCanonicalUrl = isEqual(link.params, currentRoute.params)

  return !isCanonicalUrl
}

function useRedirectToCanonicalLinkIfNeeded() {
  const currentRoute = useRoute()

  const nuxtApp = useNuxtApp()

  async function redirectToCanonicalLinkIfNeeded(
    link: ModelComparisonCompareModels['link'],
  ) {
    if (!shouldRedirect(link, currentRoute)) return

    const newRoute = {
      ...currentRoute,
      params: link.params,
    }

    /** navigateTo is a composable that can only be run in a context where it has access to the Nuxt instance
     * see @https://nuxt.com/docs/api/composables/use-nuxt-app#runwithcontext
     * and navigateTo source code @https://github.com/nuxt/nuxt/blob/fd7d9d26e260f0b16ad7848e5f5425548854da62/packages/nuxt/src/app/composables/router.ts#L112
     */
    await nuxtApp.runWithContext(() => navigateTo(newRoute, { replace: true }))
  }

  return { redirectToCanonicalLinkIfNeeded }
}

export function useFetchModelsComparisonWithDeps({
  initializableAlgoliaClient,
}: {
  initializableAlgoliaClient: InitializableAlgoliaClient<ModelsComparisonAlgoliaHit>
}) {
  const { modelsSlugs } = useGetModelsSlugsFromRoute()

  const { search } = useModelsComparisonAlgoliaSearch(
    initializableAlgoliaClient,
  )

  const { buildModelsComparisonData } = useBuildModelsComparisonData()

  const { redirectToCanonicalLinkIfNeeded } =
    useRedirectToCanonicalLinkIfNeeded()

  return useAsyncData(async () => {
    const comparisonResults = await $httpFetch(getModelsComparisonResults, {
      pathParams: {
        model1: modelsSlugs[0],
        model2: modelsSlugs[1],
      },
    })

    await redirectToCanonicalLinkIfNeeded(comparisonResults.link)

    const modelsData = await search(comparisonResults)

    const comparison = buildModelsComparisonData({
      modelsData,
      comparisonResults,
    })

    return comparison
  })
}

export function useFetchModelsComparison() {
  return useFetchModelsComparisonWithDeps({
    initializableAlgoliaClient:
      algoliasearch as InitializableAlgoliaClient<ModelsComparisonAlgoliaHit>,
  })
}
